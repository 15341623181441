import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const Split = () => {
    const { t } = useTranslation();

    const buttonStyle = {
        padding: '15px 30px',
        fontSize: '16px',
        fontWeight: '500',
        borderRadius: '12px',
        border: 'none',
        color: 'white',
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        margin: '10px',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 15px rgba(66, 99, 235, 0.2)',
        backdropFilter: 'blur(10px)',
        transform: 'translateY(0)',
        cursor: 'pointer'
    };

    const appStoreStyle = {
        ...buttonStyle,
        background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(28, 28, 28, 0.8) 100%)',
    };

    const webPanelStyle = {
        ...buttonStyle,
        background: 'linear-gradient(135deg, rgb(45, 206, 204) 0%, rgb(45, 206, 204) 100%)',
        boxShadow: '0 4px 15px rgba(45, 206, 204, 0.2)'
    };

    const iconStyle = {
        marginRight: '12px',
        fontSize: '22px',
        transition: 'transform 0.3s ease'
    };

    const buttonHoverEffect = (e) => {
        e.currentTarget.style.transform = 'translateY(-2px)';
        e.currentTarget.style.boxShadow = e.currentTarget.style.background.includes('rgb(45, 206, 204)') 
            ? '0 8px 20px rgba(45, 206, 204, 0.3)'
            : '0 8px 20px rgba(0, 0, 0, 0.2)';
        const icon = e.currentTarget.querySelector('svg');
        if (icon) {
            icon.style.transform = 'scale(1.1)';
        }
    };

    const buttonLeaveEffect = (e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow = e.currentTarget.style.background.includes('rgb(45, 206, 204)')
            ? '0 4px 15px rgba(45, 206, 204, 0.2)'
            : '0 4px 15px rgba(0, 0, 0, 0.1)';
        const icon = e.currentTarget.querySelector('svg');
        if (icon) {
            icon.style.transform = 'scale(1)';
        }
    };

    return (
        <>
            <div className="main-content">
                {/* First */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="rn-splite-style bg-color-blackest">
                            <div className="split-wrapper">
                                <div className="row no-gutters radius-10 align-items-center">
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="thumbnail">
                                            <img src="./images/managerPanel.png" alt="split Images" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="split-inner">
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <h4 className="title">{t('ManagerPanel')}</h4>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <p className="description">{t('ManagerPanelDesc')}</p>
                                            </ScrollAnimation>
                                            <div className="panel-links mt-4" style={{ display: 'flex', gap: '15px' }}>
                                                <a href="https://apps.apple.com/tr/app/summit-vista/id6496853906" 
                                                   style={appStoreStyle} 
                                                   onMouseEnter={buttonHoverEffect}
                                                   onMouseLeave={buttonLeaveEffect}>
                                                    <FontAwesomeIcon icon={faApple} style={iconStyle} />
                                                    App Store
                                                </a>
                                                <a href="https://dashboard.specialhospitality.com/" 
                                                   style={webPanelStyle}
                                                   onMouseEnter={buttonHoverEffect}
                                                   onMouseLeave={buttonLeaveEffect}>
                                                    <FontAwesomeIcon icon={faGlobe} style={iconStyle} />
                                                    Web Panel
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second */}
                <div className="rn-splite-style bg-color-blackest">
                    <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <h4 className="title">{t('OwnerPanel')}</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <p className="description">{t('OwnerPanelDesc')}</p>
                                    </ScrollAnimation>
                                    <div className="panel-links mt-4" style={{ display: 'flex', gap: '15px' }}>
                                        <a href="https://apps.apple.com/tr/app/summit-vista/id6496853906" 
                                           style={appStoreStyle}
                                           onMouseEnter={buttonHoverEffect}
                                           onMouseLeave={buttonLeaveEffect}>
                                            <FontAwesomeIcon icon={faApple} style={iconStyle} />
                                            App Store
                                        </a>
                                        <a href="https://dashboard.specialhospitality.com/" 
                                           style={webPanelStyle}
                                           onMouseEnter={buttonHoverEffect}
                                           onMouseLeave={buttonLeaveEffect}>
                                            <FontAwesomeIcon icon={faGlobe} style={iconStyle} />
                                            Web Panel
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail">
                                    <img src="./images/ownerPanel.png" alt="split Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Third */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="rn-splite-style bg-color-blackest">
                            <div className="split-wrapper">
                                <div className="row no-gutters radius-10 align-items-center">
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="thumbnail">
                                            <img src="./images/guest.png" alt="split Images" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="split-inner">
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <h4 className="title">{t('GuestPanel')}</h4>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <p className="description">{t('GuestPanelDesc')}</p>
                                            </ScrollAnimation>
                                            <div className="panel-links mt-4" style={{ display: 'flex', gap: '15px' }}>
                                                <a href="https://apps.apple.com/tr/app/bestill-experience/id6739133952" 
                                                   style={appStoreStyle}
                                                   onMouseEnter={buttonHoverEffect}
                                                   onMouseLeave={buttonLeaveEffect}>
                                                    <FontAwesomeIcon icon={faApple} style={iconStyle} />
                                                    App Store
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Split;
