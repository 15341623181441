import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import AboutOne from '../elements/about/AboutOne';
import AboutOne2 from '../elements/about/AboutOne2';
import AboutBeStill from '../elements/about/AboutBeStill';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Slider from '../elements/slider/slider';
import GoogleMap from "../elements/about/GoogleMap";
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import SlpitOne from '../elements/split/SlpitOne';
import Chat from '../common/chat_query';
import SlipThree from "../elements/split/SlipThree";
import SlpitContainer from "../elements/split/SlpitContainer";
import { useTranslation } from "react-i18next";


const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title="About Us" />
            <Chat/>
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
            <div className="slider-area slider-style-1 height-950 bg_image" data-black-overlay="5" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg15.jpg)`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner pt--80 text-center">
                                <div>
                                    <h3 className="rn-sub-badge"><span className="theme-gradient">{t('AboutDetails')}</span></h3>
                                </div>
                                <h1 className="title display-one">{t('SpecialCompany')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Slider Area  */}


               
                <br />
                <div className="container" id="sumtech-smart">
                <AboutOne />            
                </div>                
                <Separator />

                {/* Start Elements Area  */}    
                <div >
                    <div className="container" id='smart-apt'>
                        <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle={t('Solutions')}
                                title={t('SmartHotels')}
                            />
                        </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                            <AboutOne2 />    
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="wrapper plr--65 plr_sm--15">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t('Gallery')}
                                    title={t('SmartRooms')}
                                    description=""
                                />
                            </div>
                        </div>
                        <Slider />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />

                 {/* Start Elements Area  */}    
                 <div >
                    <div className="container" id='smart-apt'>
                        <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle={t('Solutions')}
                                title={t('bestill')}
                            />
                        </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                            <AboutBeStill />    
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />

                {/* Start Elements Area  */}
                <div className='container'>
                <div className="rwt-split-area rn-section-gap">
                        <div className="wrapper">
                        <SlpitContainer/>
                        </div>
                </div>
                </div>
                {/* End Elements Area  */}
                <Separator />

                {/* Start Elements Area  */}
                <div className="col-8 mt_md--30 mt_sm--30" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <GoogleMap/>
                </div>
                <div className="rwt-callto-action-area rn-section-gapBottom ">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                
             
                {/* End Elements Area  */}
              
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AboutUs;
